import React from 'react';
import { Col, Container, ResponsiveEmbed, Row } from 'react-bootstrap';
import { Disqus, Footer, Layout, Navbar, PageHeader, Poster, Sponsor } from '@components';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { orderBy } from 'lodash';
import { videoFallback } from '@variables';
import '@scss/high-school-project-page.scss';
import { graphql } from 'gatsby';

const HighSchoolProjectPage = ({ data }) => {
  const { project } = data.postgres;

  return <Layout className="high-school-project-page">
    <Navbar year={project.year} />
    <PageHeader
      title={project?.title}
      subTextComponent={<p>{project?.school.name} - {project?.year}</p>}
    />

    <Container style={{ marginBottom: `1rem`, marginTop: `3rem` }}>
      <Row className="mb-4 justify-content-center">
        {
          project?.videoUrl &&
            <Col lg={8} style={{ marginBottom: `1rem` }}>
              <ResponsiveEmbed aspectRatio="16by9">
                <iframe
                  title={project?.title}
                  src={videoFallback ? project?.backupVideoUrl : project?.videoUrl}
                  className="embed-responsive-item"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </ResponsiveEmbed>
            </Col>
        }
      </Row>
      <Row className="mb-4 align-items-center">
        <Col>
          <h3>Abstract</h3>
          <p>
            {project?.abstract}
          </p>
        </Col>
        {
          project?.poster &&
            <Col lg={6} style={{ marginBottom: `1rem` }}>
              <Poster poster={project?.posterFile} />
            </Col>
        }
        {
          !project?.poster &&
            <Col lg={6} style={{ marginBottom: `1rem`, textAlign: `center` }}>
              <GatsbyImage
                imgClassName="logo"
                image={getImage(project?.school.logoFile)}
                alt={`${project?.school.name} Logo`}
              />
            </Col>
        }
      </Row>
      <Row className="mb-4">
        <Col>
          <h2>Members</h2>
          <Row className="mb-4">
            {orderBy(project?.members, `name`).map(member =>
              <Col
                key={member.name}
                md={project?.members.length < 2 ? 4 : 2}
                xs={project?.members.length < 2 ? 8 : 4}
                className="text-center"
              >
                {
                  member?.image ?
                    <GatsbyImage
                      image={getImage(member?.imageFile)}
                      alt={member.name}
                    /> :
                    <StaticImage layout="fullWidth" src="../assets/images/person-placeholder.png" alt={member.name} />
                }
                <p>{member.name}</p>
              </Col>)}
          </Row>
          <p><strong>Advisor: </strong>{project?.advisor}</p>
          {
            project?.website &&
              <p>
                <strong>Live Demo: </strong>
                <a href={project?.website}
                  target="_blank"
                  rel="noopener noreferrer">
                  {project?.website}
                </a>
              </p>
          }
        </Col>
      </Row>
      {project.judgingSessionId && <Row className="mb-4 ml-0">
        <p><a href={`/judging/${project.judgingSessionId}`}>Click here to view the Judging session</a></p>
      </Row>}
      <Disqus
        identifier={`high-school-${project?.id}`}
        title={project?.title}
        pageLocation={`/high-school/${project?.id}`}
      />
    </Container>

    <Sponsor year={project.year} />
    <Footer />

  </Layout>;
};

export const query = graphql`
  query HighSchoolProjectPageQuery($projectId: Int!) {
    postgres {
      project: highSchoolProjectById(id: $projectId) {
        id
        abstract
        advisor
        backupVideoUrl
        poster
        posterFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        title
        videoUrl
        website
        year
        members: highSchoolMembersByProjectIdList {
          image
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          name
        }
        school: highSchoolByHighSchoolId {
          logo
          logoFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          name
        }
        judgingSessionId
      }
    }
  }
`;

export default HighSchoolProjectPage;
